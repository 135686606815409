import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import Checkbox from "@/components/ui/forms/checkbox";
import { Input, JollyTextField } from "@/components/ui/textfield";
import {
  ExpandedSearchEditor,
  InlineSearchEditor,
} from "@/components/ui/tiptap-editor/TiptapEditor";
import { useFeature } from "@/lib/feature-management";
import { cn } from "@/lib/utils";
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";
import useArticleDeletionStore from "@/store/news/articleDelete.slice";
import {
  filterBySearchTerms,
  isFeedFiltered,
} from "@/store/news/filterSearch.actions";
import {
  useFilterCounts,
  useFilteredFeedItems,
  usePercentages,
} from "@/store/news/news.selectors";
import useFeedStore, { useFeedParamsStore } from "@/store/useFeedStore";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { shallow, useShallow } from "zustand/shallow";
import { FeedSort } from "./FeedSort";

interface FeedSearchProps {
  style?: React.CSSProperties;
}

export const FeedSearch: React.FC<FeedSearchProps> = ({ style }) => {
  const filteredFeedIds = useFeedStore((state) => state.filteredFeedIds);
  const articleIdsToDelete = useArticleDeletionStore(
    (state) => state.articleIdsToDelete,
  );
  const deleteAllArticles = useArticleDeletionStore(
    (state) => state.deleteAllArticles,
  );
  const toggleDeleteAllArticles = useArticleDeletionStore(
    (state) => state.toggleDeleteAllArticles,
  );
  const [fetchingFeed, fetchingSearch] = useFeedStore(
    useShallow((state) => [state.fetchingFeed, state.fetchingSearch]),
  );
  const searchTerms = useFeedParamsStore((state) => state.searchTerms, shallow);

  const { displayed: displayPercentage } = usePercentages();
  const { displayed: displayedItemsCount } = useFilterCounts();

  const filteredFeedItems = useFilteredFeedItems();

  const [isFilteredUI, setIsFilteredUI] = useState<boolean>(isFeedFiltered());
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  // * Feature Flags*/
  const hasKeyMessagesAccess = useFeature("enable-key-messages", false);

  useEffect(() => {
    setIsFilteredUI(isFeedFiltered());
  }, [filteredFeedItems]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Only to restore the existing search terms
    setSearchTerm(searchTerms);
  }, [searchTerms]);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    if (isAdvancedSearchOpen && !hasKeyMessagesAccess) return;

    debounceTimeout.current = setTimeout(() => {
      filterBySearchTerms(searchTerm);
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  //** TODO: Once key messages are good to go, remove handelSubmitAdvancedSearch, handleKeyPress, debouncetimeout, and useEffect
  //  reason being search is now fired by on search button click and not on enter key press
  // */

  const handleSubmitAdvancedSearch = () => {
    filterBySearchTerms(searchTerm);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterBySearchTerms(searchTerm);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const renderNumberOfArticles = () => {
    return (
      <>
        <span className="font-semibold">
          {addCommasToNumbers(displayedItemsCount)}{" "}
        </span>
        <span>{pluralize(displayedItemsCount, "Article")} </span>
        {isFilteredUI &&
          displayPercentage !== 0 &&
          displayPercentage &&
          !Number.isNaN(displayPercentage) && (
            <span>({displayPercentage}%)</span>
          )}
        {isFilteredUI && displayPercentage === 0 && (
          <span className={"text-xs text-gray-600"}>
            (Adjust filters to see more)
          </span>
        )}
      </>
    );
  };

  const renderSearchInput = () => {
    if (!isAdvancedSearchOpen) {
      if (hasKeyMessagesAccess) {
        return (
          <InlineSearchEditor
            handleSearch={handleSearch}
            handleExpand={() => setIsAdvancedSearchOpen(true)}
            content={searchTerm}
          />
        );
      }

      return (
        <div className="relative flex items-center w-auto">
          <MagnifyingGlass
            size={16}
            className="text-gray-600 absolute left-2.5"
          />
          <Input
            placeholder="Search for articles"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
            className={cn(
              "w-80 mr-1 h-9 py-2 pl-[2rem] pr-[4.5rem] items-end transition-width duration-200 ease-in-out",
            )}
          />
          <Button
            type="button"
            variant="input"
            size="input"
            onPress={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
          >
            Expand
          </Button>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div
        className={cn("max-w-[956px] lg:mx-auto  z-30 sticky", "top-[88px]")}
        style={style}
      >
        {isAdvancedSearchOpen &&
          (hasKeyMessagesAccess ? (
            <ExpandedSearchEditor
              handleSearch={handleSearch}
              handleExpand={() => setIsAdvancedSearchOpen(false)}
              content={searchTerm}
              isSearching={fetchingSearch}
            />
          ) : (
            <div className="px-4 py-4 bg-white">
              <JollyTextField
                textArea
                label="Advanced Search"
                value={searchTerm}
                onChange={(searchTerm) => setSearchTerm(searchTerm)}
                onKeyDown={handleKeyPress}
              />
              <div className="flex justify-end gap-2 mt-2">
                <Button
                  type="button"
                  variant="ghost"
                  onPress={() => setIsAdvancedSearchOpen(false)}
                >
                  Cancel
                </Button>
                <Button variant="outline" onPress={handleSubmitAdvancedSearch}>
                  Submit
                </Button>
              </div>
            </div>
          ))}
        <div className="flex px-3 pt-2 items-baseline justify-between bg-white">
          <div className="text-sm flex-grow text-slate-900/95 flex-1 leading-tight tracking-[0.14px] flex">
            {fetchingFeed || fetchingSearch ? (
              <Skeleton className="w-32 h-5 bg-slate-300/50" />
            ) : (
              <>
                <Checkbox
                  checked={deleteAllArticles}
                  isSelectAll={true}
                  isPartialSelect={
                    !!articleIdsToDelete.length &&
                    articleIdsToDelete.length !== filteredFeedIds.size
                  }
                  onCheckedChange={() =>
                    toggleDeleteAllArticles(Array.from(filteredFeedIds))
                  }
                >
                  {renderNumberOfArticles()}
                </Checkbox>
              </>
            )}
            {hasKeyMessagesAccess && (
              <div className={"ml-3"}>
                <FeedSort />
              </div>
            )}
          </div>

          <div className="flex gap-1 items-end pb-1.5">
            {renderSearchInput()}
            {!hasKeyMessagesAccess && <FeedSort />}
          </div>
        </div>
        <div className="z-10 bg-gradient-to-b from-white via-white/50 via-70% h-4 -mx-1 to-transparent" />
      </div>
    </>
  );
};
